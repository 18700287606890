<template>
  <v-container>
    <v-card v-if="order.orderId" class="order-card">
      <v-card-title>
        <span class="headline">Détails de la Commande - {{ order.orderRef }}</span>
      </v-card-title>
      <v-divider></v-divider>

      <!-- Order details -->
      <v-card-subtitle>
        <strong>Client :</strong> {{ order.clientName || 'Non spécifié' }}
      </v-card-subtitle>
      <v-card-subtitle>
        <strong>Email Client:</strong> {{ order.clientEmail || 'Non spécifié' }}
      </v-card-subtitle>
      <v-card-subtitle>
        <strong>Nombre d'articles :</strong> {{ order.bookOrderResponseList.length }}
      </v-card-subtitle>
      <v-card-subtitle>
        <strong>Adresse :</strong> {{ order.orderAddress }}
      </v-card-subtitle>
      <v-card-subtitle>
        <strong>Type de Paiement :</strong> {{ order.orderPaymentType }}
      </v-card-subtitle>

      <!-- Order status -->
      
      <v-card-subtitle class="status-update-container">
        
        <strong>Statut de Commande :</strong>
        <div class="status-container">
          <span :class="{'status-dot-orange': order.orderState === 'EN_COURS', 'status-dot-green': order.orderState === 'EXPEDIE'}"></span>
          {{ order.orderState }}
        </div>
        <v-select
          v-model="order.orderState"
          :items="statuses"
          label="Statut de commande"
          class="status-select"
        ></v-select>
        <v-btn color="blue darken-1" @click="updateOrder" class="update-btn">Mettre à jour</v-btn>
      </v-card-subtitle>

      <!-- Book list data table -->
      <v-data-table
        :headers="bookHeaders"
        :items="order.bookOrderResponseList"
        class="elevation-2"
        hide-default-footer
      >
      <template v-slot:item.imgUrl="{ item }">
        <img :src="item.imgUrl" alt="Order Image" class="order-image" />
      </template>
        <template v-slot:item.bookPrice="{ item }">
          {{ item.bookPrice.toFixed(2) }}€
        </template>
        <template v-slot:item.bookQnt="{ item }">
          {{ item.bookQnt }}
        </template>
        <template v-slot:item.bookTVA="{ item }">
          {{ calculateTVAForBook(item).toFixed(2) }}€
        </template>
        <template v-slot:item.bookTotal="{ item }">
          {{ calculateTotalForBook(item).toFixed(2) }}€
        </template>
      </v-data-table>
    </v-card>

    <!-- TVA and Total card -->
    <v-card v-if="order.orderId" class="mt-4 summary-card">
      <v-card-title>
        <span class="headline">Résumé de la Commande</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle>
        <strong>Livraison:</strong> {{ this.library.delivery.toFixed(2) }}€
      </v-card-subtitle>
      <v-card-subtitle>
        <strong>Somme TVA (5.5%):</strong> {{ calculateTVA().toFixed(2) }}€
      </v-card-subtitle>
      <v-card-subtitle>
        <strong>Somme Total:</strong> {{ calculateTotal().toFixed(2) }}€
      </v-card-subtitle>
    </v-card>

    <!-- Snackbar for notifications -->
    <v-snackbar v-model="snackbar" :color="snackbarColor" timeout="3000">
      {{ snackbarMessage }}
      <v-btn color="white" text @click="snackbar = false">Fermer</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import Constant from "@/utils/constants";

export default {
  data() {
    return {
      order: {
        orderId: null,
        clientName: "",
        clientEmail: "",
        orderAddress: "",
        orderPaymentType: "",
        bookOrderResponseList: [],
        orderState: ""
      },
      library: {
        delivery: 0
      },
      bookHeaders: [
        { text: "image", value: "imgUrl" },
        { text: "Nom du Livre", value: "bookName" },
        { text: "Prix", value: "bookPrice" },
        { text: "Quantité", value: "bookQnt" },
        { text: "Nom Auteur", value: "authorName" },
        { text: "TVA", value: "bookTVA" },
        { text: "Total", value: "bookTotal" }
      ],
      statuses: [
        "EN_COURS",
        "EXPEDIE",
      ],
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: 'success'
    };
  },
  async created() {
    await this.fetchOrderDetails();
  },
  methods: {
    async fetchOrderDetails() {
      const orderId = this.$route.params.orderId;

      try {
        const orderResponse = await axios.get(
          `${Constant.QORANI}library/order/getOrderById/${orderId}`
        );

        const selectedOrder = orderResponse.data;

        if (selectedOrder) {
          this.order = {
            ...selectedOrder,
            bookOrderResponseList: selectedOrder.bookOrderResponseList
          };
          console.log("Selected Order:", this.order);
        } else {
          console.warn("No matching order found for ID:", orderId);
        }

        const userIdtest = localStorage.getItem('ownerId');
        const libraryResponse = await axios.get(
          `${Constant.QORANI}library/library/getByOwnerId/${userIdtest}`
        );
        this.library = libraryResponse.data;
        console.log('Library fetched:', this.library);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    },

    async updateOrder() {
      const numericOrderId = this.order.orderId;
      const orderStatus = this.order.orderState;

      if (!numericOrderId || !orderStatus) {
        console.error('Invalid order ID or order status.');
        return;
      }

      try {
        const url = `${Constant.QORANI}library/order/editOrderStatus/${numericOrderId}/staus/${orderStatus}`;
        const response = await axios.get(url);
        console.log('Order updated successfully:', response.data);

        // Show success notification
        this.snackbarMessage = 'Statut de la commande mis à jour avec succès!';
        this.snackbarColor = 'success';
        this.snackbar = true;

        this.fetchOrderDetails();
      } catch (error) {
        console.error('Error updating the order status:', error);
        // Show error notification
        this.snackbarMessage = 'Erreur lors de la mise à jour du statut de la commande.';
        this.snackbarColor = 'error';
        this.snackbar = true;
      }
    },

    calculateTVAForBook(book) {
      const TVA_RATE = 0.055;
      return book.bookPrice * book.bookQnt * TVA_RATE;
    },

    calculateTotalForBook(book) {
      return (book.bookPrice * book.bookQnt) + this.calculateTVAForBook(book);
    },

    calculateTVA() {
      const TVA_RATE = 0.055;
      return this.order.bookOrderResponseList.reduce((total, item) => total + (item.bookPrice * item.bookQnt * TVA_RATE), 0);
    },

    calculateTotal() {
      return this.order.bookOrderResponseList.reduce((total, item) => total + (item.bookPrice * item.bookQnt), 0) + this.calculateTVA() + this.library.delivery;
    }
  }
};
</script>





<style scoped>
.order-image {
  width: 50px;  /* Set desired width */
  height: auto; /* Maintain aspect ratio */
}
.status-container {
  display: flex;
  align-items: center;
}

.status-dot-orange {
  width: 10px;
  height: 10px;
  background-color: orange;
  border-radius: 50%;
  margin-right: 5px;
}

.status-dot-green {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-right: 5px;
}

.v-card {
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.order-card {
  background-color: #f9f9f9;
  margin-bottom: 20px;

}

.summary-card {
  background-color: #fff;
  margin-top: 20px;

}

.v-card-title {
  background-color: #1976d2;
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
}

.v-card-subtitle {
  font-weight: normal;
  color: #333;
}

.status-update-container {
  display: flex;
  align-items: center;
  gap: 16px; /* Adjust the space between items as needed */
}

.status-select {
  max-width: 300px;
}

.update-btn {
  margin-left: 8px; /* Space between select and button */

}

.footer-totals {
  background-color: #eeeeee;
  border-top: 1px solid #ddd;
}

.v-data-table {
  background-color: #ffffff;
}

.v-data-table th {
  background-color: #eeeeee;
  font-weight: bold;
}

.v-data-table td {
  font-size: 0.9em;
}
</style>
